@import '~antd/dist/antd.css';

* {
  font-family: Open Sans, sans-serif;
  margin: 0;
  padding: 0;
}

.text-center {
  text-align: center;
}

.ant-layout-header, .ant-menu-dark, .ant-menu-dark .ant-menu-sub {
  background: white;
  line-height: 0px;
}

.ant-menu.ant-menu-dark .ant-menu-item-selected, .ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected {
  background-color: white;
}

.ant-menu.ant-menu-dark .ant-menu-item-selected > a {
  color: #88B81B;
}

.ant-menu-dark .ant-menu-item, .ant-menu-dark .ant-menu-item-group-title, .ant-menu-dark .ant-menu-item > a {
  color: #333333;
}

.ant-menu-dark .ant-menu-item:hover, .ant-menu-dark .ant-menu-item:hover > a {
  color: #004489 !important;
}

.ant-form-item-control {
  line-height: 20px;
}

.content-auto {
  background: white;
  padding: 24px;
  min-height: calc(100vh - 100px);
  margin: 16px;
}